import "./App.css";
import Landing from "./Pages/Landing";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
function App() {
  return (
    <div className="App">
      <ReactNotifications />
      <Landing></Landing>
    </div>
  );
}

export default App;
