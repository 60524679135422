import "./style.css";
import i1 from "./img/1.png";
import i2 from "./img/2.png";
import { Zoom } from "react-reveal";
export default function Certificate() {
  return (
    <div className="certificate">
      <Zoom left bottom>
        <img src={i1} className="f1" alt="" />
      </Zoom>
      <Zoom right top>
        <img src={i2} className="f2" alt="" />
      </Zoom>
      <div>
        <h3>Our official certificates</h3>
        <p>
          At OPORA.DIGITAL, our official certificates underscore our dedication
          to industry excellence. These prestigious credentials from leading
          organizations highlight our commitment to delivering top-tier
          services. They serve as a testament to our team’s expertise,
          reliability, and continuous pursuit of professional development.{" "}
        </p>
      </div>
    </div>
  );
}
