import "./style.css";
import video from "./img/video.png";
import opora from "./img/opopa.svg";
import { Fade, Zoom } from "react-reveal";
export default function Reach() {
  return (
    <div className="reach">
      <div className="leftReach">
        <Zoom>
          <img src={video} alt="" />
        </Zoom>
      </div>
      <div className="rightReact">
        <img src={opora} alt="" />

        <h3>let’s Reach your</h3>

        <h4>
          business goals <br /> together
        </h4>
        <p>Unleash the potential of your business with our expertise.</p>
        <div className="we">
          <Fade left>
            <p style={{ color: "rgba(82, 0, 255, 1)" }}>
              We
              <span>
                Design and implement digital strategies and solutions tailored
                to your needs.
              </span>
            </p>
          </Fade>
          <Fade right>
            <p style={{ color: "rgba(47, 158, 220, 1)" }}>
              You
              <span>
                Achieve business automation, effective lead generation, and
                measurable success.
              </span>
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
}
