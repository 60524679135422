import { useState } from "react";
import "./style.css";
export default function Cases({ open, setOpen }) {
  const [currentCase, setCurrentCase] = useState(0);
  return (
    <div className="cases" id="cases">
      <h2>Our cases</h2>
      <div
        className="cse"
        style={currentCase == 0 ? { maxHeight: "1000px" } : { padding: "0" }}
      >
        <img src="/img/c1.png" alt="" />
        <div>
          <h4>Vijf Sterren Renovatie</h4>
          <p>Renovation and construction company in the Netherlands</p>
          <span>
            Vijf Sterren Renovatie is a leading renovation and construction
            company based in The Hague, Netherlands. With over 14 years of
            experience, they specialize in turnkey renovation projects completed
            within 4.5 months by professional teams.
          </span>
          <div className="res">Results:</div>
          <ul>
            <li>3 clients</li>
            <li>345 € advertising budget</li>
            <li>115 € price per clients</li>
          </ul>
          <div className="learn" onClick={() => setOpen(true)}>
            learn more
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="8.4"
                stroke="white"
                stroke-width="1.2"
              />
              <path
                d="M16.4243 12.4243C16.6586 12.1899 16.6586 11.81 16.4243 11.5757L12.6059 7.75736C12.3716 7.52304 11.9917 7.52304 11.7574 7.75736C11.523 7.99167 11.523 8.37157 11.7574 8.60589L15.1515 12L11.7574 15.3941C11.523 15.6284 11.523 16.0083 11.7574 16.2426C11.9917 16.477 12.3716 16.477 12.6059 16.2426L16.4243 12.4243ZM8 12.6L16 12.6L16 11.4L8 11.4L8 12.6Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="caseEm" onClick={() => setCurrentCase(0)}>
        <div
          className="caseBody"
          style={currentCase == 0 ? { background: "#fff" } : {}}
        >
          <p>1</p>
          <div>
            <p style={currentCase == 0 ? { color: "black" } : {}}>
              Vijf Sterren Renovatie
            </p>
            <span>Renovation and construction company in the Netherlands</span>
          </div>
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="15"
              cy="15.5"
              r="11.25"
              stroke={currentCase == 0 ? "black" : "white"}
              stroke-width="1.5"
            />
            <path
              d="M20.6735 16.0303C20.9664 15.7374 20.9664 15.2626 20.6735 14.9697L15.9005 10.1967C15.6076 9.90381 15.1327 9.90381 14.8398 10.1967C14.5469 10.4896 14.5469 10.9645 14.8398 11.2574L19.0825 15.5L14.8398 19.7426C14.5469 20.0355 14.5469 20.5104 14.8398 20.8033C15.1327 21.0962 15.6076 21.0962 15.9005 20.8033L20.6735 16.0303ZM9.85742 16.25L20.1431 16.25L20.1431 14.75L9.85742 14.75L9.85742 16.25Z"
              fill={currentCase == 0 ? "black" : "white"}
            />
          </svg>
        </div>
      </div>
      <div
        className="cse"
        style={currentCase == 1 ? { maxHeight: "1000px" } : { padding: "0" }}
      >
        <img src="/img/c2.png" alt="" />
        <div>
          <h4>Navit Developer from Montenegro</h4>
          <p>apartment cost from 50 000 € to 500 000 € </p>
          <span>
            Navit is a prominent European real estate developer specializing in
            high-quality apartments. With a focus on quality and customer
            satisfaction, Navit delivers exceptional living spaces for a diverse
            market. Their commitment to excellence ensures each project meets
            the highest standards.
          </span>
          <div className="res">Results:</div>
          <ul>
            <li>3 sales</li>
            <li>1731 € advertising budget</li>
            <li>577€ price per clients</li>
          </ul>
          <div className="learn" onClick={() => setOpen(true)}>
            learn more
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="8.4"
                stroke="white"
                stroke-width="1.2"
              />
              <path
                d="M16.4243 12.4243C16.6586 12.1899 16.6586 11.81 16.4243 11.5757L12.6059 7.75736C12.3716 7.52304 11.9917 7.52304 11.7574 7.75736C11.523 7.99167 11.523 8.37157 11.7574 8.60589L15.1515 12L11.7574 15.3941C11.523 15.6284 11.523 16.0083 11.7574 16.2426C11.9917 16.477 12.3716 16.477 12.6059 16.2426L16.4243 12.4243ZM8 12.6L16 12.6L16 11.4L8 11.4L8 12.6Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="caseEm" onClick={() => setCurrentCase(1)}>
        <div
          className="caseBody"
          style={currentCase == 1 ? { background: "#fff" } : {}}
        >
          <p>2</p>
          <div>
            <p style={currentCase == 1 ? { color: "black" } : {}}>
              Navit Developer from Montenegro
            </p>
            <span>apartment cost from 50 000 € to 500 000 € </span>
          </div>
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="15"
              cy="15.5"
              r="11.25"
              stroke={currentCase == 1 ? "black" : "white"}
              stroke-width="1.5"
            />
            <path
              d="M20.6735 16.0303C20.9664 15.7374 20.9664 15.2626 20.6735 14.9697L15.9005 10.1967C15.6076 9.90381 15.1327 9.90381 14.8398 10.1967C14.5469 10.4896 14.5469 10.9645 14.8398 11.2574L19.0825 15.5L14.8398 19.7426C14.5469 20.0355 14.5469 20.5104 14.8398 20.8033C15.1327 21.0962 15.6076 21.0962 15.9005 20.8033L20.6735 16.0303ZM9.85742 16.25L20.1431 16.25L20.1431 14.75L9.85742 14.75L9.85742 16.25Z"
              fill={currentCase == 1 ? "black" : "white"}
            />
          </svg>
        </div>
      </div>
      <div
        className="cse"
        style={currentCase == 2 ? { maxHeight: "1000px" } : { padding: "0" }}
      >
        <img src="/img/c3.png" alt="" />
        <div>
          <h4>Anna Abramova’s</h4>
          <p>Online School</p>
          <span>
            Anna Abramova’s Online School specializes in investment education,
            attracting hundreds of participants to their webinars through
            targeted and effective marketing strategies.
          </span>
          <div className="res">Results:</div>
          <ul>
            <li>757 applications</li>
            <li>915 $ advertising budget</li>
            <li>1,2 $ price per applecant</li>
          </ul>
          <div className="learn" onClick={() => setOpen(true)}>
            learn more
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="8.4"
                stroke="white"
                stroke-width="1.2"
              />
              <path
                d="M16.4243 12.4243C16.6586 12.1899 16.6586 11.81 16.4243 11.5757L12.6059 7.75736C12.3716 7.52304 11.9917 7.52304 11.7574 7.75736C11.523 7.99167 11.523 8.37157 11.7574 8.60589L15.1515 12L11.7574 15.3941C11.523 15.6284 11.523 16.0083 11.7574 16.2426C11.9917 16.477 12.3716 16.477 12.6059 16.2426L16.4243 12.4243ZM8 12.6L16 12.6L16 11.4L8 11.4L8 12.6Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="caseEm" onClick={() => setCurrentCase(2)}>
        <div
          className="caseBody"
          style={currentCase == 2 ? { background: "#fff" } : {}}
        >
          <p>3</p>
          <div>
            <p style={currentCase == 2 ? { color: "black" } : {}}>
              Anna Abramova’s
            </p>
            <span>Online School</span>
          </div>
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="15"
              cy="15.5"
              r="11.25"
              stroke={currentCase == 2 ? "black" : "white"}
              stroke-width="1.5"
            />
            <path
              d="M20.6735 16.0303C20.9664 15.7374 20.9664 15.2626 20.6735 14.9697L15.9005 10.1967C15.6076 9.90381 15.1327 9.90381 14.8398 10.1967C14.5469 10.4896 14.5469 10.9645 14.8398 11.2574L19.0825 15.5L14.8398 19.7426C14.5469 20.0355 14.5469 20.5104 14.8398 20.8033C15.1327 21.0962 15.6076 21.0962 15.9005 20.8033L20.6735 16.0303ZM9.85742 16.25L20.1431 16.25L20.1431 14.75L9.85742 14.75L9.85742 16.25Z"
              fill={currentCase == 2 ? "black" : "white"}
            />
          </svg>
        </div>
      </div>
      <div
        className="cse"
        style={currentCase == 3 ? { maxHeight: "1000px" } : { padding: "0" }}
      >
        <img src="/img/c4.png" alt="" />
        <div>
          <h4>Tara Home Decor</h4>
          <p>Online home decor store (E-com)</p>
          <span>
            Tara Home Decor offers distinctive furniture and decor that blend
            art and history to create unique items for your home. With a diverse
            collection inspired by cultures from around the world, they provide
            handcrafted treasures that reflect individuality, sustainability,
            and cultural connection.
          </span>
          <div className="res">Results:</div>
          <ul>
            <li>972 leads</li>
            <li>1702 $ advertising budget</li>
            <li>1.75 $ cost per lead</li>
          </ul>
          <div className="learn" onClick={() => setOpen(true)}>
            learn more
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="8.4"
                stroke="white"
                stroke-width="1.2"
              />
              <path
                d="M16.4243 12.4243C16.6586 12.1899 16.6586 11.81 16.4243 11.5757L12.6059 7.75736C12.3716 7.52304 11.9917 7.52304 11.7574 7.75736C11.523 7.99167 11.523 8.37157 11.7574 8.60589L15.1515 12L11.7574 15.3941C11.523 15.6284 11.523 16.0083 11.7574 16.2426C11.9917 16.477 12.3716 16.477 12.6059 16.2426L16.4243 12.4243ZM8 12.6L16 12.6L16 11.4L8 11.4L8 12.6Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="caseEm" onClick={() => setCurrentCase(3)}>
        <div
          className="caseBody"
          style={currentCase == 3 ? { background: "#fff" } : {}}
        >
          <p>4</p>
          <div>
            <p style={currentCase == 3 ? { color: "black" } : {}}>
              Tara Home Decor
            </p>
            <span>Online store (E-com)</span>
          </div>
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="15"
              cy="15.5"
              r="11.25"
              stroke={currentCase == 3 ? "black" : "white"}
              stroke-width="1.5"
            />
            <path
              d="M20.6735 16.0303C20.9664 15.7374 20.9664 15.2626 20.6735 14.9697L15.9005 10.1967C15.6076 9.90381 15.1327 9.90381 14.8398 10.1967C14.5469 10.4896 14.5469 10.9645 14.8398 11.2574L19.0825 15.5L14.8398 19.7426C14.5469 20.0355 14.5469 20.5104 14.8398 20.8033C15.1327 21.0962 15.6076 21.0962 15.9005 20.8033L20.6735 16.0303ZM9.85742 16.25L20.1431 16.25L20.1431 14.75L9.85742 14.75L9.85742 16.25Z"
              fill={currentCase == 3 ? "black" : "white"}
            />
          </svg>
        </div>
      </div>
      <div
        className="cse"
        style={currentCase == 4 ? { maxHeight: "1000px" } : { padding: "0" }}
      >
        <img src="/img/c5.png" alt="" />
        <div>
          <h4>Montmari</h4>
          <p>Real estate agency in Thailand</p>
          <span>
            Renowned for its quality properties, Montmari is a leading real
            estate agency in Thailand. Their targeted marketing campaigns
            achieved impressive lead generation.
          </span>
          <div className="res">Results:</div>
          <ul>
            <li>323 leads</li>
            <li>3090 $ advertising budget</li>
            <li>9.5 $ cost per lead</li>
          </ul>
          <div className="learn" onClick={() => setOpen(true)}>
            learn more
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="8.4"
                stroke="white"
                stroke-width="1.2"
              />
              <path
                d="M16.4243 12.4243C16.6586 12.1899 16.6586 11.81 16.4243 11.5757L12.6059 7.75736C12.3716 7.52304 11.9917 7.52304 11.7574 7.75736C11.523 7.99167 11.523 8.37157 11.7574 8.60589L15.1515 12L11.7574 15.3941C11.523 15.6284 11.523 16.0083 11.7574 16.2426C11.9917 16.477 12.3716 16.477 12.6059 16.2426L16.4243 12.4243ZM8 12.6L16 12.6L16 11.4L8 11.4L8 12.6Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="caseEm" onClick={() => setCurrentCase(4)}>
        <div
          className="caseBody"
          style={currentCase == 4 ? { background: "#fff" } : {}}
        >
          <p>5</p>
          <div>
            <p style={currentCase == 4 ? { color: "black" } : {}}>Montmari</p>
            <span>Real estate agency in Thailand</span>
          </div>
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="15"
              cy="15.5"
              r="11.25"
              stroke={currentCase == 4 ? "black" : "white"}
              stroke-width="1.5"
            />
            <path
              d="M20.6735 16.0303C20.9664 15.7374 20.9664 15.2626 20.6735 14.9697L15.9005 10.1967C15.6076 9.90381 15.1327 9.90381 14.8398 10.1967C14.5469 10.4896 14.5469 10.9645 14.8398 11.2574L19.0825 15.5L14.8398 19.7426C14.5469 20.0355 14.5469 20.5104 14.8398 20.8033C15.1327 21.0962 15.6076 21.0962 15.9005 20.8033L20.6735 16.0303ZM9.85742 16.25L20.1431 16.25L20.1431 14.75L9.85742 14.75L9.85742 16.25Z"
              fill={currentCase == 4 ? "black" : "white"}
            />
          </svg>
        </div>
      </div>
      <div className="discuss" onClick={() => setOpen(true)}>
        See More cases
      </div>
    </div>
  );
}
