import { useState } from "react";
import "./style.css";
import i1 from "./img/1.png";
import i2 from "./img/2.png";
import i3 from "./img/3.png";
import YouTube from "react-youtube";
export default function Reviews() {
  const [slide, setSlide] = useState(0);
  const [link, setLink] = useState("");
  const opts = {
    height: "590",
    width: "1040",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  return (
    <div className="reviews" id="reviews">
      {link.length > 0 && (
        <div
          className="seeVideo"
          id="rev"
          onClick={(e) => e.target.id == "rev" && setLink("")}
        >
          <YouTube videoId={link} opts={opts} />
        </div>
      )}
      <div className="sliderTrain">
        <div
          className="slB"
          style={{ transform: `translateX(-${slide * 100}%)` }}
        >
          <div className="slide">
            <img src={i1} alt="" />
            <div className="slideContent">
              <p>
                Nikita <br />
                Alekseev
              </p>
              <span>founder Atis group</span>
              <article>
                With over 6 years of experience in organizing business processes
                and structures, our company serves clients in over 12 countries.
                We collaborated with OPORA.DIGITAL to create a lead magnet,
                build an autofunnel, and develop a website with valuable
                materials. Despite challenges, including employee changes, the
                team remained dedicated and delivered high-quality results.
                Their keen sense of design and organization stands out. For
                anyone seeking competent and affordable digital services, I
                highly recommend OPORA.DIGITAL.
              </article>
              <div className="discuss" onClick={() => setLink("S_NQie-Srsw")}>
                See video rewiev
              </div>
            </div>
          </div>
          <div className="slide">
            <img src={i2} alt="" />
            <div className="slideContent">
              <p>
                Oleg <br />
                Ovcharenko
              </p>
              <span>Owner of Vijf Sterren Renovatie</span>
              <article>
                With over 14 years of experience, Vijf Sterren Renovatie has
                always relied on word-of-mouth for clients. Facing a challenging
                year, we turned to OPORA.DIGITAL for help. They revamped our
                website and launched effective ad campaigns on Facebook and
                Instagram. Within two weeks, we received over 12 inquiries,
                including some significant projects. Thanks to OPORA.DIGITAL, we
                secured the exact type of contract we needed. Highly recommend
                their services.
              </article>
              <div className="discuss" onClick={() => setLink("-z2cMf4Tgvg")}>
                See video rewiev
              </div>
            </div>
          </div>
          <div className="slide">
            <img src={i3} alt="" />
            <div className="slideContent">
              <p>
                Roman <br />
                Kuziakov
              </p>
              <span>COO online school</span>
              <article>
                Leading an online school with an annual turnover exceeding
                $400,000, I’ve collaborated with OPORA.DIGITAL on multiple
                projects. Georgii and his team consistently deliver high-quality
                results in design, traffic, and efficiency. Their exceptional
                management skills and preparedness have been invaluable. Their
                ability to push us forward, combined with top-notch organization
                and design quality, makes OPORA.DIGITAL a market leader. Highly
                recommend them.
              </article>
              <div className="discuss" onClick={() => setLink("LXUrLcfP_Co")}>
                See video rewiev
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="buttonsRew">
        <div className="left" onClick={() => slide != 0 && setSlide(slide - 1)}>
          <svg
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.06745 5.11992L15.9335 5.11992C16.6949 5.11992 17.3125 5.73748 17.3125 6.49892C17.3125 7.26037 16.6949 7.87793 15.9335 7.87793L2.06745 7.87793C1.30601 7.87793 0.688446 7.26037 0.688446 6.49892C0.688446 5.73748 1.30601 5.11992 2.06745 5.11992Z"
              fill={slide != 0 ? "rgba(126, 255, 26, 1)" : "#777"}
            />
            <path
              d="M6.68976 0.498364C7.04219 0.498364 7.39624 0.632547 7.6646 0.902529C8.20295 1.44087 8.20295 2.31387 7.6646 2.85221L4.01743 6.49938L7.6646 10.1466C8.20295 10.6849 8.20295 11.5579 7.6646 12.0962C7.12625 12.6346 6.25326 12.6346 5.71491 12.0962L1.0929 7.47423C0.554557 6.93588 0.554557 6.06289 1.0929 5.52454L5.71492 0.902529C5.98328 0.632547 6.33733 0.498364 6.68976 0.498364Z"
              fill={slide != 0 ? "rgba(126, 255, 26, 1)" : "#777"}
            />
          </svg>
        </div>
        <div
          className="right"
          onClick={() => slide != 2 && setSlide(slide + 1)}
        >
          <svg
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.06745 5.11992L15.9335 5.11992C16.6949 5.11992 17.3125 5.73748 17.3125 6.49892C17.3125 7.26037 16.6949 7.87793 15.9335 7.87793L2.06745 7.87793C1.30601 7.87793 0.688446 7.26037 0.688446 6.49892C0.688446 5.73748 1.30601 5.11992 2.06745 5.11992Z"
              fill={slide != 2 ? "rgba(126, 255, 26, 1)" : "#777"}
            />
            <path
              d="M6.68976 0.498364C7.04219 0.498364 7.39624 0.632547 7.6646 0.902529C8.20295 1.44087 8.20295 2.31387 7.6646 2.85221L4.01743 6.49938L7.6646 10.1466C8.20295 10.6849 8.20295 11.5579 7.6646 12.0962C7.12625 12.6346 6.25326 12.6346 5.71491 12.0962L1.0929 7.47423C0.554557 6.93588 0.554557 6.06289 1.0929 5.52454L5.71492 0.902529C5.98328 0.632547 6.33733 0.498364 6.68976 0.498364Z"
              fill={slide != 2 ? "rgba(126, 255, 26, 1)" : "#777"}
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
