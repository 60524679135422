import Marquee from "react-fast-marquee";
import HeaderMenu from "../../Components/HeaderMenu";
import "./style.css";
import test from "./img/test.png";
import { Zoom } from "react-reveal";
export default function Header({ open, setOpen }) {
  return (
    <div className="header">
      <HeaderMenu></HeaderMenu>
      <div className="headerContent">
        <div className="headerLeft">
          <Zoom left>
            <h1>
              Beyond {document.body.clientWidth > 640 && <br />} Marketing:
            </h1>
          </Zoom>
          <Zoom right>
            <h2>
              Total Digital {document.body.clientWidth > 640 && <br />}
              Excellence
            </h2>
          </Zoom>
          <p>
            <span>Drive sales growth, attract clients, and</span> optimize{" "}
            {document.body.clientWidth > 640 && <br />} your business with
            unparalleled efficiency.
          </p>
          <div className="discuss" onClick={() => setOpen(true)}>
            discuss project
          </div>
        </div>
        {document.body.clientWidth > 640 && (
          <div className="rightHeader">
            <div
              className="line"
              style={
                document.body.clientWidth > 640
                  ? { transform: "translateX(-13vw)", opacity: "0.2" }
                  : { transform: "translateX(60vw)" }
              }
            >
              <Marquee width="100%" direction="up" speed={20}>
                <img src="./img/s5.png" alt="" />
                <img src="./img/s6.png" alt="" />
                <img src="./img/s7.png" alt="" />
                <img src="./img/s8.png" alt="" />
              </Marquee>
            </div>
            <div
              className="line"
              style={
                document.body.clientWidth > 640
                  ? { transform: "translateX(-44.5vw)" }
                  : { transform: "translateX(-2.5vw)" }
              }
            >
              <Marquee width="100%" direction="down" speed={20}>
                <div className="omg">
                  <img src="./img/s1.png" alt="" />
                </div>
                <div className="omg">
                  <img src="./img/s2.png" alt="" />
                </div>
                <div className="omg">
                  <img src="./img/s3.png" alt="" />
                </div>
                <div className="omg">
                  <img src="./img/s4.png" alt="" />
                </div>
              </Marquee>
            </div>
            <div
              className="line"
              style={
                document.body.clientWidth > 640
                  ? { transform: "translateX(-76vw)", opacity: "0.2" }
                  : { transform: "translateX(-60vw)" }
              }
            >
              <Marquee width="100%" direction="up" speed={20}>
                <img src="./img/s9.png" alt="" />
                <img src="./img/s10.png" alt="" />
                <img src="./img/s11.png" alt="" />
                <img src="./img/s12.png" alt="" />
              </Marquee>
            </div>
          </div>
        )}
      </div>

      <div className="run">
        <Marquee>
          <p>Traffic</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Web design</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Marketing</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Chatbots and Apps</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Web development</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Neural networks</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Implementation of CRM</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p> Analytics</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>3D/AR visualization</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>SEO </p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>SMM </p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Content Creation</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Email Marketing</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Video Production</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>E-commerce Solutions</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>UI/UX Design</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Cloud Solutions</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>IT Consulting</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Mobile App Development</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Custom Software Development</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Digital Strategy Consulting</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Cybersecurity Solutions</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Automation and Process Optimization</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Blockchain Solutions</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>
        </Marquee>
      </div>
    </div>
  );
}
