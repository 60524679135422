import "./style.css";
import logo from "./img/logo.png";
export default function Footer() {
  return (
    <div className="footer">
      <div className="logoFooter">
        <img src={logo} alt="" />
        <p>We deliver results.</p>
      </div>
      <div className="footerLinks">
        <a
          href="./politic.html"
          target="_blank"
          style={{ color: "rgba(119, 119, 119, 1)" }}
        >
          Privacy Policy
        </a>
        <p>Social media</p>
        <div className="og">
          <a href="https://www.instagram.com/oporadigital" target="_blank">
            instagram
          </a>

          <a href="https://www.facebook.com/oporadigital" target="_blank">
            facebook
          </a>
          <a href="https://www.youtube.com/@oporadigital" target="_blank">
            youtube
          </a>
          <a
            href="https://www.linkedin.com/company/opora-digital"
            target="_blank"
          >
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
}
