import { useState } from "react";
import Cases from "../../Containers/10Cases";
import Reviews from "../../Containers/11Reviews";
import Team from "../../Containers/12Team";
import Certificate from "../../Containers/13Certificate";
import Consultation from "../../Containers/14Consultation";
import Header from "../../Containers/1Header";
import Clients from "../../Containers/2Clients";
import Reach from "../../Containers/3Reach";
import Solution from "../../Containers/4Solution";
import Martketing from "../../Containers/5Marketing";
import TimeForm from "../../Containers/6TimeForm";
import Tasks from "../../Containers/7Tasks";
import FinalResult from "../../Containers/8FinalResult";
import Services from "../../Containers/9Services";
import Footer from "../../Containers/Footer";
import "./style.css";
import form from "../../Containers/pop/1.png";
import { sendMessage } from "../../Api/Api";
import { Store } from "react-notifications-component";
export default function Landing() {
  const [open, setOpen] = useState(false);
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  return (
    <div className="landing">
      {open && (
        <div
          className="pop"
          id="land"
          onClick={(e) => e.target.id == "land" && setOpen(false)}
        >
          <div className="popBody">
            <img src={form} alt="" />
            <div className="popBodyForm">
              <input
                type="text"
                placeholder="NAME"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="tel"
                placeholder="PHONE*"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
              />

              <input
                type="text"
                placeholder="EMAIL"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div
                className="goTo"
                style={
                  tel.length < 6
                    ? { pointerEvents: "none", opacity: "0.4" }
                    : { cursor: "pointer" }
                }
                onClick={() => {
                  sendMessage(tel, email, name);
                  Store.addNotification({
                    title: "Success",
                    message: "We will contact you soon!",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__bounceIn"],
                    animationOut: ["animate__animated", "animate__backOutUp"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                    },
                  });
                  setOpen(false);
                }}
              >
                Submit
              </div>
              <p>in just 5 minutes, we will be able to discuss your project</p>
            </div>
          </div>
        </div>
      )}
      <Header open={open} setOpen={setOpen}></Header>
      <Clients open={open} setOpen={setOpen}></Clients>
      <Reach open={open} setOpen={setOpen}></Reach>
      <Solution open={open} setOpen={setOpen}></Solution>
      <Martketing open={open} setOpen={setOpen}></Martketing>
      <TimeForm open={open} setOpen={setOpen}></TimeForm>
      <Tasks open={open} setOpen={setOpen}></Tasks>
      <FinalResult open={open} setOpen={setOpen}></FinalResult>
      <Services open={open} setOpen={setOpen}></Services>
      <Cases open={open} setOpen={setOpen}></Cases>
      <Reviews open={open} setOpen={setOpen}></Reviews>
      <Team open={open} setOpen={setOpen}></Team>
      <Certificate open={open} setOpen={setOpen}></Certificate>
      <Consultation open={open} setOpen={setOpen}></Consultation>
      <Footer></Footer>
    </div>
  );
}
