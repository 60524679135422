import "./style.css";
import op from "./img/op.svg";
import gosha from "./img/gosha.png";
import { useState } from "react";
import { sendMessage } from "../../Api/Api";
import { Store } from "react-notifications-component";
export default function Consultation() {
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  return (
    <div className="consultation">
      <h2>
        Get your 30 minute consultation <span> for free</span>
      </h2>

      <div className="consulBody">
        <div className="leftConsul">
          <img src={gosha} alt="" />
          <div>
            <p>Georgii Baikin</p>
            <span>founder of the company</span>
            <article>
              Unlock the potential of your business with expert advice. Fill out
              the form and schedule your free 30-minute consultation with the
              founder of OPORA.DIGITAL today. Don’t miss this opportunity to get
              personalized insights and strategies tailored to your needs.
              <b> Limited slots available!</b>
            </article>
            <img src={op} alt="" />
          </div>
        </div>
        <div className="rightConsul">
          <p>
            0<span>$</span>
          </p>{" "}
          <input
            type="text"
            placeholder="NAME"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="tel"
            placeholder="PHONE*"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
          />
          <input
            type="text"
            placeholder="EMAIL"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div
            className="discuss"
            onClick={() => {
              sendMessage(tel, email, name);
              Store.addNotification({
                title: "Заявка отправлена",
                message:
                  "В ближайшее время мы свяжемся с вами для уточнения деталей",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
              setName("");
              setTel("");
              setEmail("");
            }}
            style={
              tel.length < 6 ? { pointerEvents: "none", opacity: "0.4" } : {}
            }
          >
            Book now
          </div>
          <i>in just 5 minutes, we will be able to discuss your project</i>
        </div>
      </div>
    </div>
  );
}
