import { Fade } from "react-reveal";
import "./style.css";
import { resolution } from "../../const";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { useState } from "react";
export default function Clients() {
  const [count, setCount] = useState(3);
  return (
    <div className="clients" id="about">
      <div className="leftClients">
        <h2>
          <span>Clients</span> from around the globe trust us
        </h2>
        <Fade bottom cascade>
          <div className="pluses">
            <p>
              30+
              <span>
                different <br /> countries
              </span>
            </p>
            <p>
              8+
              <span>
                years of <br /> experience
              </span>
            </p>
            <p>
              200+
              <span>
                successfull <br /> projects
              </span>
            </p>
          </div>
        </Fade>
      </div>
      <model-viewer
        src="/earth.glb"
        camera-controls
        tone-mapping="neutral"
        shadow-intensity="0"
        auto-rotate="true"
      >
        <div class="progress-bar hide" slot="progress-bar">
          <div class="update-bar"></div>
        </div>
      </model-viewer>
      {/*    <div className="countries">
        <p>Hungary</p>
        <p>Holland</p>
        <p>Serbia</p>
        <p
          style={{
            fontSize: resolution < 640 ? "7vw" : "2vw",
            color: "#fff",
            fontWeight: "600",
          }}
        >
          Turkey
        </p>
        <p>Spain</p>
        <p>France</p>
        <p>Thailand</p>
   
      </div> */}
      <Swiper
        style={{ pointerEvents: "none" }}
        direction={"vertical"}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        slidesPerView={7}
        onActiveIndexChange={() => {
          setCount(count + 1);
        }}
        className="mySwiper"
      >
        {[
          "Hungary",
          "Holland",
          "Serbia",
          "Turkey",
          "Spain",
          "France",

          "Canada",
          "America",
          "Mexico",
          "Brazil",
          "Argentina",
          "Italy",
          "Russia",
          "China",
          "Montenegro",
          "Romania",
          "Slovakia",
          "Greece",
          "Poland",
          "Latvia",

          "Colombia",
          "Norway",
          "Cyprus",
          "Germany",
          "Denmark",
          "Mauritius",
          "Croatia",
          "Thailand",
          "Indonesia",
        ].map((em, index) => {
          return (
            <SwiperSlide
              style={
                index == count
                  ? {
                      color: "#fff",
                      fontSize:
                        document.body.clientWidth > 640 ? "1.8vw" : "5vw",
                    }
                  : {}
              }
            >
              {em}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
