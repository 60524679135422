import { useState } from "react";
import "./style.css";
export default function Services({ open, setOpen }) {
  const [servicem, setService] = useState(0);
  return (
    <div className="services" id="service">
      <h2>Looking for something specific?</h2>
      <p>we are at your service</p>
      <div
        className="servicesEm"
        onClick={() => {
          setService(0);
        }}
      >
        <div className="ono">
          <p className="shet">01</p>
          <span className="serviceEmTitle">ACQUISITION & RETENTION</span>

          <div
            className="arrow"
            style={
              servicem == 0
                ? {}
                : {
                    background: "rgba(126, 255, 26, 1)",
                    transform: "rotate(180deg)",
                  }
            }
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 1.18396C5.76256 0.891063 6.23744 0.891063 6.53033 1.18396L11.3033 5.95693C11.5962 6.24982 11.5962 6.72469 11.3033 7.01759C11.0104 7.31048 10.5355 7.31048 10.2426 7.01759L6 2.77495L1.75736 7.01759C1.46447 7.31048 0.989592 7.31048 0.696699 7.01759C0.403806 6.72469 0.403805 6.24982 0.696699 5.95693L5.46967 1.18396ZM5.25 12L5.25 1.71429L6.75 1.71429L6.75 12L5.25 12Z"
                  fill="#0B0B0B"
                />
              </svg>
            </svg>{" "}
          </div>
        </div>
        <div
          className="bodyService"
          style={{ maxHeight: servicem == 0 ? "600px" : "0" }}
        >
          <article>
            We provide comprehensive pay ads solutions designed to maximize your
            return on investment. Our services include attracting cold
            audiences, retargeting warm audiences, and retantion with your
            current customer base.
          </article>
          <div className="hash">
            <span>Pay Per Click</span>
            <span>Paid Social Media</span>
            <span>Any sources</span>
            <span>traffic</span>
          </div>
          <div className="learnMe" onClick={() => setOpen(true)}>
            LEARN MORE
          </div>
        </div>
      </div>

      <div
        className="servicesEm"
        onClick={() => {
          setService(1);
        }}
      >
        <div className="ono">
          <p className="shet">02</p>
          <span className="serviceEmTitle">Web development</span>

          <div
            className="arrow"
            style={
              servicem == 1
                ? {}
                : {
                    background: "rgba(126, 255, 26, 1)",
                    transform: "rotate(180deg)",
                  }
            }
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 1.18396C5.76256 0.891063 6.23744 0.891063 6.53033 1.18396L11.3033 5.95693C11.5962 6.24982 11.5962 6.72469 11.3033 7.01759C11.0104 7.31048 10.5355 7.31048 10.2426 7.01759L6 2.77495L1.75736 7.01759C1.46447 7.31048 0.989592 7.31048 0.696699 7.01759C0.403806 6.72469 0.403805 6.24982 0.696699 5.95693L5.46967 1.18396ZM5.25 12L5.25 1.71429L6.75 1.71429L6.75 12L5.25 12Z"
                  fill="#0B0B0B"
                />
              </svg>
            </svg>{" "}
          </div>
        </div>
        <div
          className="bodyService"
          style={{ maxHeight: servicem == 1 ? "600px" : "0" }}
        >
          <article>
            Our expertise includes creating high-converting landing pages,
            robust online stores, and professional corporate websites, ensuring
            your digital presence is powerful and effective.
          </article>
          <div className="hash">
            <span>UX/UI Design</span>
            <span>Cloud Solutions</span>
            <span>Corporate websites</span>
            <span>Landing pages</span>
            <span>Online stores (E-com)</span>
            <span>Blockchain Development</span>
          </div>{" "}
          <div className="learnMe" onClick={() => setOpen(true)}>
            LEARN MORE
          </div>
        </div>
      </div>
      <div
        className="servicesEm"
        onClick={() => {
          setService(2);
        }}
      >
        <div className="ono">
          <p className="shet">03</p>
          <span className="serviceEmTitle">Analytics</span>

          <div
            className="arrow"
            style={
              servicem == 2
                ? {}
                : {
                    background: "rgba(126, 255, 26, 1)",
                    transform: "rotate(180deg)",
                  }
            }
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 1.18396C5.76256 0.891063 6.23744 0.891063 6.53033 1.18396L11.3033 5.95693C11.5962 6.24982 11.5962 6.72469 11.3033 7.01759C11.0104 7.31048 10.5355 7.31048 10.2426 7.01759L6 2.77495L1.75736 7.01759C1.46447 7.31048 0.989592 7.31048 0.696699 7.01759C0.403806 6.72469 0.403805 6.24982 0.696699 5.95693L5.46967 1.18396ZM5.25 12L5.25 1.71429L6.75 1.71429L6.75 12L5.25 12Z"
                  fill="#0B0B0B"
                />
              </svg>
            </svg>{" "}
          </div>
        </div>
        <div
          className="bodyService"
          style={{ maxHeight: servicem == 2 ? "600px" : "0" }}
        >
          <article>
            Empower your business with advanced analytics solutions. We provide
            comprehensive data analysis tools that enable you to make informed,
            data-driven decisions. Monitor key performance indicators (KPIs) to
            gauge the success of your business, track employee workflows for
            optimized productivity, and visualize financial flows for clear,
            actionable insights. Our analytics services are designed to enhance
            your business operations, improve efficiency, and drive growth.
          </article>
          <div className="hash">
            <span>Looker Studio</span>
            <span>google analytics</span>
            <span>Power BI</span>
            <span>Yandex.Metrika</span>
            <span>Tableau</span>
            <span>Yandex DataLens</span>
          </div>{" "}
          <div className="learnMe" onClick={() => setOpen(true)}>
            LEARN MORE
          </div>
        </div>
      </div>
      <div
        className="servicesEm"
        onClick={() => {
          setService(3);
        }}
      >
        <div className="ono">
          <p className="shet">04</p>
          <span className="serviceEmTitle">Chatbots and Apps</span>

          <div
            className="arrow"
            style={
              servicem == 3
                ? {}
                : {
                    background: "rgba(126, 255, 26, 1)",
                    transform: "rotate(180deg)",
                  }
            }
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 1.18396C5.76256 0.891063 6.23744 0.891063 6.53033 1.18396L11.3033 5.95693C11.5962 6.24982 11.5962 6.72469 11.3033 7.01759C11.0104 7.31048 10.5355 7.31048 10.2426 7.01759L6 2.77495L1.75736 7.01759C1.46447 7.31048 0.989592 7.31048 0.696699 7.01759C0.403806 6.72469 0.403805 6.24982 0.696699 5.95693L5.46967 1.18396ZM5.25 12L5.25 1.71429L6.75 1.71429L6.75 12L5.25 12Z"
                  fill="#0B0B0B"
                />
              </svg>
            </svg>{" "}
          </div>
        </div>
        <div
          className="bodyService"
          style={{ maxHeight: servicem == 3 ? "600px" : "0" }}
        >
          <article>
            Enhance your customer interactions and streamline your business
            operations with our advanced chatbots and custom apps. Our solutions
            help you scale your sales, maximize profit, accumulate and activate
            warm audiences, increase brand loyalty, and grow retention and
            revenue. Additionally, we address individual needs, such as
            automating interactions with partners or franchisees, digitizing,
            and optimizing business processes, ensuring that your business
            operates efficiently and effectively.
          </article>
          <div className="hash">
            <span>Web Application</span>
            <span>Chatbot Development</span>
            <span>Cross-Platform Development</span>
            <span>Mobile App Development</span>
            <span>API & Integrations</span>
          </div>{" "}
          <div className="learnMe" onClick={() => setOpen(true)}>
            LEARN MORE
          </div>
        </div>
      </div>
      <div
        className="servicesEm"
        onClick={() => {
          setService(4);
        }}
      >
        <div className="ono">
          <p className="shet">05</p>
          <span className="serviceEmTitle">Implementation of CRM</span>

          <div
            className="arrow"
            style={
              servicem == 4
                ? {}
                : {
                    background: "rgba(126, 255, 26, 1)",
                    transform: "rotate(180deg)",
                  }
            }
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 1.18396C5.76256 0.891063 6.23744 0.891063 6.53033 1.18396L11.3033 5.95693C11.5962 6.24982 11.5962 6.72469 11.3033 7.01759C11.0104 7.31048 10.5355 7.31048 10.2426 7.01759L6 2.77495L1.75736 7.01759C1.46447 7.31048 0.989592 7.31048 0.696699 7.01759C0.403806 6.72469 0.403805 6.24982 0.696699 5.95693L5.46967 1.18396ZM5.25 12L5.25 1.71429L6.75 1.71429L6.75 12L5.25 12Z"
                  fill="#0B0B0B"
                />
              </svg>
            </svg>{" "}
          </div>
        </div>
        <div
          className="bodyService"
          style={{ maxHeight: servicem == 4 ? "600px" : "0" }}
        >
          <article>
            Enhance your operations and customer relations with our CRM systems.
            Manage all incoming leads efficiently, ensure convenient client
            communication, automate sales, and maintain managerial control.
            Develop strategic sales plans seamlessly. With our CRM solutions,
            automate routine tasks, gain actionable insights through analytics,
            and foster a productive working environment, resulting in increased
            sales, higher profits, and reduced client loss.
          </article>
          <div className="hash">
            <span>KOMMO</span>
            <span>AMOCRM</span>
            <span>BITTRIX24</span>
          </div>{" "}
          <div className="learnMe" onClick={() => setOpen(true)}>
            LEARN MORE
          </div>
        </div>
      </div>
      <div
        className="servicesEm"
        onClick={() => {
          setService(5);
        }}
      >
        <div className="ono">
          <p className="shet">06</p>
          <span className="serviceEmTitle">3D/AR Modeling & Rendering</span>

          <div
            className="arrow"
            style={
              servicem == 5
                ? {}
                : {
                    background: "rgba(126, 255, 26, 1)",
                    transform: "rotate(180deg)",
                  }
            }
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 1.18396C5.76256 0.891063 6.23744 0.891063 6.53033 1.18396L11.3033 5.95693C11.5962 6.24982 11.5962 6.72469 11.3033 7.01759C11.0104 7.31048 10.5355 7.31048 10.2426 7.01759L6 2.77495L1.75736 7.01759C1.46447 7.31048 0.989592 7.31048 0.696699 7.01759C0.403806 6.72469 0.403805 6.24982 0.696699 5.95693L5.46967 1.18396ZM5.25 12L5.25 1.71429L6.75 1.71429L6.75 12L5.25 12Z"
                  fill="#0B0B0B"
                />
              </svg>
            </svg>{" "}
          </div>
        </div>
        <div
          className="bodyService"
          style={{ maxHeight: servicem == 5 ? "600px" : "0" }}
        >
          <article>
            Our 3D/AR modeling services are tailored for developers and real
            estate agents to bring projects to life with immersive, interactive
            experiences. By integrating advanced 3D/AR technology, you can
            enhance customer engagement, and accelerate the sales process.
          </article>
          <div className="hash">
            <span>Landscape</span>
            <span>Architecture</span>
          </div>{" "}
          <div className="learnMe" onClick={() => setOpen(true)}>
            LEARN MORE
          </div>
        </div>
      </div>
      <div
        className="servicesEm"
        onClick={() => {
          setService(6);
        }}
      >
        <div className="ono">
          <p className="shet">07</p>
          <span className="serviceEmTitle">
            Integration of neural networks into your business
          </span>

          <div
            className="arrow"
            style={
              servicem == 6
                ? {}
                : {
                    background: "rgba(126, 255, 26, 1)",
                    transform: "rotate(180deg)",
                  }
            }
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 1.18396C5.76256 0.891063 6.23744 0.891063 6.53033 1.18396L11.3033 5.95693C11.5962 6.24982 11.5962 6.72469 11.3033 7.01759C11.0104 7.31048 10.5355 7.31048 10.2426 7.01759L6 2.77495L1.75736 7.01759C1.46447 7.31048 0.989592 7.31048 0.696699 7.01759C0.403806 6.72469 0.403805 6.24982 0.696699 5.95693L5.46967 1.18396ZM5.25 12L5.25 1.71429L6.75 1.71429L6.75 12L5.25 12Z"
                  fill="#0B0B0B"
                />
              </svg>
            </svg>{" "}
          </div>
        </div>
        <div
          className="bodyService"
          style={{ maxHeight: servicem == 6 ? "600px" : "0" }}
        >
          <article>
            Enhance your business with the power of neural networks, which
            optimize costs, automate processes, and improve efficiency.
            Implement AI-driven customer service for instant support, utilize
            predictive analytics for accurate forecasting, and personalize
            marketing campaigns to increase conversion rates. Neural networks
            streamline operations, ensure quality control, detect fraud, and
            enhance overall productivity, transforming how you do business and
            driving significant growth.
          </article>
          <div className="hash">
            <span>AI Integrations</span>
            <span>Custom GPT Apps</span>
            <span>AI Chatbot</span>
            <span>AI Content</span>
            <span>AI Video</span>
            <span>AI Audio</span>
          </div>{" "}
          <div className="learnMe" onClick={() => setOpen(true)}>
            LEARN MORE
          </div>
        </div>
      </div>
    </div>
  );
}
