import Marquee from "react-fast-marquee";
import "./style.css";
export default function FinalResult({ open, setOpen }) {
  return (
    <div className="finalResult">
      <h2>Final result</h2>
      <p>Growth of key performance indicators</p>
      <div className="finalResultBody">
        <div className="ulF">
          <div className="titleF">
            Growing{" "}
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.716064"
                y="0.5"
                width="32"
                height="32"
                rx="5"
                fill="white"
                fill-opacity="0.05"
              />
              <g clip-path="url(#clip0_1332_3440)">
                <path
                  d="M16.2161 7.5L10.7161 15.1219L13.9303 15.1219L12.3456 25.5L20.0858 25.5L18.5011 15.1219L21.7161 15.1219L16.2161 7.5Z"
                  fill="#7EFF1A"
                />
              </g>
              <defs>
                <clipPath id="clip0_1332_3440">
                  <rect
                    width="18"
                    height="11"
                    fill="white"
                    transform="translate(10.7161 25.5) rotate(-90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="ulFem">
            <img src="./img/f1.svg" alt="" />
            <p>Net profit</p>
          </div>
          <div className="ulFem">
            <img src="./img/f2.svg" alt="" />
            <p>Company turnover</p>
          </div>
          <div className="ulFem">
            <img src="./img/f3.svg" alt="" />
            <p>Qualified leads</p>
          </div>
          <div className="ulFem">
            <img src="./img/f4.svg" alt="" />
            <p>Management efficiency</p>
          </div>
          <div className="ulFem">
            <img src="./img/f5.svg" alt="" />
            <p>Incoming applications</p>
          </div>
        </div>
        <img src="./img/rocket.png" className="rocket" alt="" />
        <div className="ulF">
          <div className="titleF">
            Decreasing{" "}
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.0258789"
                y="0.5"
                width="32"
                height="32"
                rx="5"
                fill="white"
                fill-opacity="0.05"
              />
              <g clip-path="url(#clip0_1332_3452)">
                <path
                  d="M15.5259 25.5L10.0259 17.8781L13.2401 17.8781L11.6554 7.5L19.3956 7.5L17.8109 17.8781L21.0259 17.8781L15.5259 25.5Z"
                  fill="#5200FF"
                />
              </g>
              <defs>
                <clipPath id="clip0_1332_3452">
                  <rect
                    width="18"
                    height="11"
                    fill="white"
                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 10.0259 7.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="ulFem">
            <img src="./img/f6.svg" alt="" />
            <p>Untargeted Marketing Costs</p>
          </div>
          <div className="ulFem">
            <img src="./img/f7.svg" alt="" />
            <p>Advertising cost</p>
          </div>
          <div className="ulFem">
            <img src="./img/f8.svg" alt="" />
            <p>Application cost</p>
          </div>
          <div className="ulFem">
            <img src="./img/f9.svg" alt="" />
            <p>Customer cost</p>
          </div>
          <div className="ulFem">
            <img src="./img/f10.svg" alt="" />
            <p>Customer churn rate</p>
          </div>
        </div>
      </div>
      <div className="Contactys">
        <p>Contact us</p>
        <h4>
          Learn more about
          <span> complex solutions</span>
        </h4>
        <div className="discuss" onClick={() => setOpen(true)}>
          View Solutions
        </div>
      </div>
      <div className="run">
        <Marquee>
          <p>Traffic</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Web design</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Marketing</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Chatbots and Apps</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Web development</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Neural networks</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Implementation of CRM</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p> Analytics</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>3D/AR visualization</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>SEO </p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>SMM </p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Content Creation</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Email Marketing</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Video Production</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>E-commerce Solutions</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>UI/UX Design</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Cloud Solutions</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>IT Consulting</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Mobile App Development</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Custom Software Development</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Digital Strategy Consulting</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Cybersecurity Solutions</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Automation and Process Optimization</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>

          <p>Blockchain Solutions</p>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.5L10.7374 7.76264L18 9.5L10.7374 11.2374L9 18.5L7.26264 11.2374L0 9.5L7.26264 7.76264L9 0.5Z"
              fill="white"
            />
          </svg>
        </Marquee>
      </div>
    </div>
  );
}
