import "./style.css";
import clock from "./img/clock.png";
import { Slide } from "react-reveal";
import { useState } from "react";
import { Store } from "react-notifications-component";
import { sendMessage } from "../../Api/Api";
export default function TimeForm() {
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  return (
    <div className="timeForm">
      <img src={clock} alt="" />

      <div className="act">
        <span>Contact us</span>
        <h3>
          <span>Need to act</span> <br />
          no time to wait?
        </h3>
        <p>
          Benefit from our expertise and the{" "}
          <span>experience accumulated over 8 years </span>
          by filling out the form.
        </p>
      </div>

      <div className="rightConsul">
        <input
          type="text"
          placeholder="NAME"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="tel"
          placeholder="PHONE*"
          value={tel}
          onChange={(e) => setTel(e.target.value)}
        />
        <input
          type="text"
          placeholder="EMAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div
          className="discuss"
          onClick={() => {
            sendMessage(tel, email, name);
            Store.addNotification({
              title: "Заявка отправлена",
              message:
                "В ближайшее время мы свяжемся с вами для уточнения деталей",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__bounceIn"],
              animationOut: ["animate__animated", "animate__backOutUp"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            setName("");
            setTel("");
            setEmail("");
          }}
          style={
            tel.length < 6 ? { pointerEvents: "none", opacity: "0.4" } : {}
          }
        >
          Book now
        </div>
        <i>in just 5 minutes, we will be able to discuss your project</i>
      </div>
    </div>
  );
}
