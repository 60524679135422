import "./style.css";
import logo from "./logo.png";
import earth from "./earth.svg";
import men from "./men.svg";
import { useState } from "react";
export default function HeaderMenu() {
  const [open, setOpen] = useState(false);
  return (
    <div className="headerMenu">
      {document.body.clientWidth > 640 && (
        <img src={logo} alt="" className="logo" />
      )}
      <div className="lang">EN</div>
      <img src={earth} className="langChange" alt="" />
      {document.body.clientWidth < 640 && (
        <img src={logo} alt="" className="logo" />
      )}
      {document.body.clientWidth < 640 && (
        <img
          src={men}
          className="menuf"
          alt=""
          onClick={() => setOpen(!open)}
        />
      )}
      <ul
        style={
          document.body.clientWidth < 640
            ? { transform: `translateX(${open ? "0" : "53vw"})` }
            : {}
        }
      >
        {document.body.clientWidth < 640 && (
          <img
            src={men}
            className="menuf"
            alt=""
            onClick={() => setOpen(!open)}
          />
        )}
        <li>
          <a href="">home</a>
        </li>
        <li>
          <a href="#service" onClick={() => setOpen(!open)}>
            service
          </a>
        </li>
        <li>
          <a href="#cases" onClick={() => setOpen(!open)}>
            cases
          </a>
        </li>
        <li>
          <a href="#reviews" onClick={() => setOpen(!open)}>
            REVIEWS
          </a>
        </li>
        <li>
          <a href="#about" onClick={() => setOpen(!open)}>
            about us
          </a>
        </li>
      </ul>
    </div>
  );
}
