import "./style.css";
import rock from "./img/rock.svg";
import f from "./img/f.svg";
import fm from "./img/fm.svg";
export default function Tasks() {
  return (
    <div className="tasks">
      <div className="leftTasks">
        <p>efficient</p>
        <h3>
          <span>Complete Solutions</span> <br />
          of any task
        </h3>
        <span>we provide</span>
        <img src={rock} alt="" />
      </div>
      <div className="rightTasks">
        <img src={document.body.clientWidth > 640 ? f : fm} alt="" />
      </div>
    </div>
  );
}
