import "./style.css";
import i1 from "./img/1.svg";
import i2 from "./img/2.svg";
import i3 from "./img/3.svg";
import i4 from "./img/4.svg";
import i5 from "./img/5.svg";
import i6 from "./img/6.svg";
import { Rotate } from "react-reveal";
export default function Martketing() {
  return (
    <div className="marketing">
      <h2>
        Your success - <span>Our mission!</span>
      </h2>
      <p> You no longer need to:</p>
      <Rotate left top cascade>
        <div className="blocksM">
          <div className="blockM">
            <img src={i1} alt="" />
            <p>Compensating salaries</p>
            <span>for sales/marketing departments that are idling.</span>
          </div>
          <div className="blockM">
            <img src={i2} alt="" />
            <p>Consistently draining</p>
            <span>the budget on endless tests.</span>
          </div>
          <div className="blockM">
            <img src={i3} alt="" />
            <p>Constantly altering plans</p>
            <span>and shifting the launch dates of advertising/funnels.</span>
          </div>
          <div className="blockM">
            <img src={i4} alt="" />
            <p>Attempting to obtain responses</p>
            <span>from freelancers who have ceased to communicate.</span>
          </div>
          <div className="blockM">
            <img src={i5} alt="" />
            <p>Establish communication </p>
            <span>
              between traffic and development / analytics / sales departments,
              etc.
            </span>
          </div>
          <div className="blockM">
            <img src={i6} alt="" />
            <p>Launching recruitment funnels</p>
            <span>and sifting through dozens of contractors.</span>
          </div>
        </div>
      </Rotate>
      <svg
        className="move"
        width="36"
        height="35"
        viewBox="0 0 36 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6209 24.4335L16.6209 10.5675C16.6209 9.80604 17.2385 9.18848 17.9999 9.18848C18.7613 9.18848 19.3789 9.80604 19.3789 10.5675L19.3789 24.4335C19.3789 25.195 18.7613 25.8125 17.9999 25.8125C17.2385 25.8125 16.6209 25.195 16.6209 24.4335Z"
          fill="#777777"
        />
        <path
          d="M11.9984 19.8122C11.9984 19.4598 12.1325 19.1057 12.4025 18.8374C12.9409 18.299 13.8139 18.299 14.3522 18.8374L17.9994 22.4845L21.6466 18.8374C22.1849 18.299 23.0579 18.299 23.5962 18.8374C24.1346 19.3757 24.1346 20.2487 23.5962 20.787L18.9742 25.4091C18.4359 25.9474 17.5629 25.9474 17.0245 25.4091L12.4025 20.787C12.1325 20.5187 11.9984 20.1646 11.9984 19.8122Z"
          fill="#777777"
        />
        <path
          d="M0.499999 17.5C0.5 7.85091 8.34909 -1.1868e-06 18 -7.64949e-07C27.6509 -3.43095e-07 35.5 7.85091 35.5 17.5C35.5 27.1491 27.6491 35 18 35C8.35091 35 0.499999 27.1491 0.499999 17.5ZM32.4 17.5C32.4 9.56 25.94 3.1 18.0018 3.1C10.0636 3.1 3.60364 9.56 3.60364 17.5C3.60364 25.44 10.0636 31.8982 18.0018 31.8982C25.94 31.8982 32.3982 25.44 32.3982 17.5L32.4 17.5Z"
          fill="#777777"
        />
      </svg>
    </div>
  );
}
