import "./style.css";
import team from "./img/team.png";
import { Fade, Rotate } from "react-reveal";

export default function Team() {
  return (
    <div className="team">
      <div className="leftTeam">
        <div>Our crew</div>
        <h3>Founder of Opora.Digital and his professional team</h3>
        <p>
          All specialists have completed corporate training and passed internal
          certification.
        </p>
      </div>
      <Fade right bottom>
        <img src={team} alt="" />
      </Fade>
    </div>
  );
}
